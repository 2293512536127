import classNames from 'classnames';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Separator } from '../../base/minor/Common';
import './SettingsNavigation.scss';

export function SettingsNavigation({ isAdminRights, isPersonalSettings, isBillingPage, isSiteSettings, isWebWidget, isMembersPage, isAsklyAssistantPage, isEmailPage, canSave }) {
    const user = useSelector(state => state.user)
    const { t } = useTranslation("settings_nav")
    let hasAssistantFeature = user?.siteDetails?.siteConfig?.features?.includes?.("AsklyAssistant");
    let hasEmailFeature = user?.siteDetails?.siteConfig?.features?.includes?.("AsklyEmails");

    return (
        <div className='settings-nav  main-card mr-2'>
            <div className='d-flex flex-column'>
                <h2 className='text-center'>{t("Settings")}</h2>
                <div>
                    <div className='d-flex flex-column'>
                        <div className='mt-3 mb-2'>
                            <Separator className="my-0"><b>{t("Personal")}</b></Separator>
                            <div className='separator-desc text-center'>{user?.userDetails?.name}</div>
                        </div>
                        <div className='ml-1'>
                            <Link to="/settings/personal" className={classNames("option-wrap tour-step-personal-settings", { active: isPersonalSettings })}>
                                <div className={classNames("group-sub-title")}>{t("Account")}</div>
                                <div className='group-desc'>{t("Choose notifications, languages and other personal settings")}</div>
                            </Link>
                        </div>
                    </div>
                    <div className='d-flex mt-2 flex-column'>
                        <div className='mb-2'>
                            <div className='my-1'>
                                <Separator className="my-0"><b>{t("Organization")}</b></Separator>
                                <div className='separator-desc text-center'>{user?.siteDetails?.name}</div>
                            </div>
                        </div>
                        <div className='ml-1'>
                            <Link to="/settings/site" className={classNames("option-wrap tour-step-site-settings", { active: isSiteSettings })}>
                                <div className={classNames("group-sub-title")}>{t("General")}</div>
                                <div className='group-desc'>{t("Script, tutorials and social media integration")}</div>
                            </Link>
                            {isAdminRights && <Link to="/settings/site/web-widget" className={classNames("option-wrap tour-step-widget-settings", { active: isWebWidget })}>
                                <div className={classNames("group-sub-title")}>{t("Web chat design")}</div>
                                <div className='group-desc'>{t("Customise your chat, add calls and FAQs")}</div>
                            </Link>}
                            <Link to="/settings/site/users" className={classNames("option-wrap tour-step-users-settings", { active: isMembersPage })}>
                                <div className={classNames("group-sub-title  ")}>{t("Team members")}</div>
                                <div className='group-desc'>{t("Invite agents and set their access level")}</div>
                            </Link>
                            {hasAssistantFeature && <Link to="/settings/site/assistant" className={classNames("option-wrap tour-step-users-settings", { active: isAsklyAssistantPage })}>
                                <div className={classNames("group-sub-title  ")}>{t("AI Assistant")}</div>
                                <div className='group-desc'>{t("Configure your chatbot and add documents")}</div>
                            </Link>}
                            {hasEmailFeature && isAdminRights && <Link to="/settings/site/email" className={classNames("option-wrap tour-step-users-settings", { active: isEmailPage })}>
                                <div className={classNames("group-sub-title  ")}>{t("Email")}</div>
                                <div className='group-desc'>{t("Configure your email fowrwarding and add domains")}</div>
                            </Link>}
                            {isAdminRights && <Link to="/settings/subscription" className={classNames("option-wrap ", { active: isBillingPage })}>
                                <div className='group-sub-title' >{t("Billing")}</div>
                                <div className='group-desc'>{t("Subscribe, change plan and see invoices")}</div>
                            </Link>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
import i18next from 'i18next';

import { addErrorToast, addInfoToast } from '../components/base/ToastManager';
import { saveSiteSettings as apiSaveSiteSettings, fetchChats, getMyDetails, getSiteDetails, saveMySettings, switchSite } from "../logic/api";
import tabNotifications from '../logic/tabNotification';
import { changeLanguage, executePromiseMinTime } from '../logic/util';
import wsEventManager from '../logic/wsEventmanager';
import { fetchChatData } from './activeChat';
import { finishFetchChatList } from './chatList';

const initialUserState = {
  isLoaded: false,
  isSiteLoaded: false,
  userDetails: {},
  siteDetails: {},
  changingSite: false,
  ws: null,
  error: null,
}

// TODO Critical clear 
export const WS_ACTION = {
  START_SESSION: "WS_ACTION_START_SESSION",
  END_SESSION: "WS_ACTION_END_SESSION",
};

export const siteDetails = (details) => ({ type: "SITE:DETAILS", payload: details });
export const userDetails = (details) => ({ type: "ME:DETAILS", payload: details });
export const integrationAdd = (data) => ({ type: "SITE:ADD_INTEGRATION", payload: data });
export const integrationDelete = (data) => ({ type: "SITE:DELETE_INTEGRATION", payload: data });
export const startSession = (ws) => ({ type: WS_ACTION.START_SESSION, payload: ws });
export const endSession = () => ({ type: WS_ACTION.END_SESSION });


export const allInitialRequests = () => (dispatch, getStore) => {
  return Promise.all([getSiteDetails(), getMyDetails(), fetchChats({ activeFilter: "inbox" })]).then(([respSiteDetails, respUserDetails, listData]) => {
    dispatch(userDetails(respUserDetails))
    dispatch(siteDetails(respSiteDetails))
    dispatch(finishFetchChatList(listData))
    tabNotifications.SetUserPref(respUserDetails)
  }).catch(e => {
    addErrorToast(i18next.t("Unable to fetch user data"))
    throw e
  });
}

export const changeActiveSite = (newSiteID, contactID) => (dispatch, getStore) => {
  getStore().user?.ws?.close();
  dispatch({ type: "SITE:CHANGE_SITE" })


  executePromiseMinTime(switchSite(newSiteID)
    .then(() => wsEventManager.switchSiteID(newSiteID))
    .then(() => dispatch(allInitialRequests()))
    .catch((e) => {
      addErrorToast(i18next.t("Something went wrong. Unable to switch site"))
    }).then(() => {
      if (contactID) {
        return dispatch(fetchChatData(contactID))
      }
    }), 800).finally(() => {
      dispatch({ type: "SITE:CHANGE_SITE_DONE" })
    })
}

export const updatSiteSettings = (widgetSettings, siteSettings) => (dispatch, getStore) => {
  return apiSaveSiteSettings({ widget: widgetSettings, site: siteSettings }).then(r => {
    dispatch({ type: "SITE:DETAILS/SETTINGS", payload: { ...siteSettings, settings: widgetSettings } })
  })
}

export const updateMySettings = (settings) => (dispatch, getStore) => {
  saveMySettings(settings).then(r => {
    dispatch({ type: "ME:DETAILS/SETTINGS", payload: settings })
    tabNotifications.SetUserPref(settings)
    addInfoToast(i18next.t("User settings saved"))
  }).catch(err => {
    addErrorToast(i18next.t("Unable to change user settings"))
  });
}

export const fetchMyDetails = () => (dispatch, getStore) => {
  return Promise.all([getSiteDetails(), getMyDetails()]).then(([respSiteDetails, respUserDetails]) => {
    dispatch(userDetails(respUserDetails))
    dispatch(siteDetails(respSiteDetails))
    tabNotifications.SetUserPref(respUserDetails)
    return changeLanguage(respUserDetails.prefLang).then(() => {
      return [respSiteDetails, respUserDetails]
    })
  }).catch(e => {
    addErrorToast("Unable to fetch user data")
    throw e
  });
}

export const fetchUserDetails = () => (dispatch, getStore) => {
  getMyDetails().then((respUserDetails) => {
    tabNotifications.SetUserPref(respUserDetails)
    dispatch(userDetails(respUserDetails))
    return changeLanguage(respUserDetails.prefLang)
  }).catch(e => {
    addErrorToast(i18next.t("Unable to fetch user data"))
    throw e
  });
};

export const fetchSiteDetails = () => (dispatch, getStore) => {
  getSiteDetails().then((respSiteDetails) => {
    dispatch(siteDetails(respSiteDetails))
  }).catch(e => {
    addErrorToast(i18next.t("Unable to fetch user data"))
    throw e
  });
};

export const currentUserReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case "SITE:CHANGE_SITE":
      return { ...initialUserState, changingSite: true };
    case "SITE:CHANGE_SITE_DONE":
      return { ...state, changingSite: false };
    case WS_ACTION.START_SESSION:
      return { ...state, ws: action.payload };
    case "ME:DETAILS/SETTINGS":
      return { ...state, userDetails: { ...state.userDetails, ...action.payload } };
    case "ME:DETAILS":
      return { ...state, userDetails: action.payload, isLoaded: true };
    case "SITE:DETAILS":
      return { ...state, siteDetails: action.payload, isSiteLoaded: true };
    case "CHAT:ADD_TAG":
      if (!state.siteDetails.allTags?.find(t => t.id === action.payload.tagging?.tag?.id)) {
        let newTags = [...state.siteDetails.allTags || []] // Clone array so prevProps wouldn't update also
        newTags.push(action.payload.tagging.tag)
        let newState = { ...state };
        newState.siteDetails.allTags = newTags
        return newState
      }

      return state
    case "SITE:BREAK":
      let newSettingsWrap = { ...state.siteDetails?.siteSettingsWrap, breakUntil: action.payload?.breakUntil }
      return { ...state, siteDetails: { ...state.siteDetails, siteSettingsWrap: newSettingsWrap } }
    case "SITE:DETAILS/SETTINGS":
      return { ...state, siteDetails: { ...state.siteDetails, siteSettingsWrap: action.payload } };
    case "SITE:SETTINGS":
      return { ...state, siteDetails: { ...state.siteDetails, siteSettingsWrap: { settings: action.payload } } };
    case "SITE:FB_UNSUBSCRIBE":
      return { ...state, siteDetails: { ...state.siteDetails, facebookChannel: state.siteDetails.facebookChannel.filter(p => p.pageID !== action.payload) } };
    case "SITE:ADD_INTEGRATION":
      if (!state.siteDetails.integrationNames?.find(t => t.integrationType === action.payload.integrationType)) {
        let newList = [...state.siteDetails.integrationNames || []]
        newList.push(action.payload)
        let newState = { ...state };
        newState.siteDetails.integrationNames = newList
        return newState
      }
      return state;
    case "SITE:DELETE_INTEGRATION":

      if (state.siteDetails.integrationNames?.find(t => t.integrationType === action.payload.integrationType)) {
        var newList = state.siteDetails.integrationNames?.filter(function (value) {
          return value.integrationType !== action.payload.integrationType;
        });

        let newState = { ...state };
        newState.siteDetails.integrationNames = newList
        return newState
      }

      return state;
    case "EXTERNAL:SITE_MESSAGE":
      let sitesClone = state.userDetails?.sites
      let selectedSite = sitesClone?.find?.((site) => site.siteID === action.payload.siteID)
      if (selectedSite) {
        let array = selectedSite.unreadContacts || []

        if (array.indexOf(action.payload.contactID) === -1) {
          array.push(action.payload.contactID)
          selectedSite.unreadContacts = array

          return { ...state }
        }
      }
      return state;
    default:
      return state;
  }
};

import emojiData from '@emoji-mart/data';
import EmojiPicker from '@emoji-mart/react';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { connect, useSelector } from 'react-redux';
import { sendMessage as apiSendMessage, sendMessageNote } from '../../logic/api';
import { debounce, getLanguage, getLanguages, getLanguagesLabelValue, htmlDecode } from '../../logic/util';
import wsEventManager from '../../logic/wsEventmanager';
import { setChatSeen } from '../../state/activeChat';
import ChatInputEmail from './ChatInputEmail';
import NotesArea from './ChatInputNotes';
import { QuickReplyToggle } from './QuickReply';
import { addErrorToast } from './ToastManager';
import { Separator, SeparatorVertical, ThinButton } from './minor/Common';
import { SearchDropdownWithLabelPrefix, SearchDropdownWithQuickPicks } from './minor/SearchDropdown';
import useOutsideClick from './useOutsideClick';


export function ChatFooterWrap({ archived, activeChat, user, selfAssignChat, loading, isAssignedToMe, status, sendInputs, assignChatTo, reopenChat, assignedUser, isWaitingAssignment, askToBlock, askArchiveChat, changeVisiorLanguage, visitorLang, supportLang, changeSupportLanguage }) {
    const sourceType = activeChat?.contact?.sourceType
    return <>
        {!loading && isAssignedToMe && status === "assigned" && !archived && sourceType === "EMAIL" && <ChatInputEmail activeChat={activeChat} />}
        {!loading && isAssignedToMe && status === "assigned" && !archived && sourceType !== "EMAIL" && <ChatInputFooterWithProps sendMessage={sendInputs} />}
        {!loading && isWaitingAssignment && <ChatAcceptFooter onClick={selfAssignChat}
            activeChat={activeChat}
            archive={askArchiveChat}
            askToBlock={askToBlock}
            changeVisiorLanguage={changeVisiorLanguage}
            visitorLang={visitorLang}
            supportLang={supportLang}
            changeSupportLanguage={changeSupportLanguage}
        />}
        {!loading && !isAssignedToMe && (status === "assigned" || status === "askly_assistant") && <NotAssignedFooter onClick={() => { assignChatTo(user.userDetails.id) }} status={status} assignedUser={assignedUser} visitorLang={visitorLang} />}
        {!loading && archived && <ArchivedFooter reopenChat={reopenChat} isBlocked={activeChat.contact?.isBlocked} />}
    </>
}

export function InlineImageAttachmentManager({ children, className, files = [], filesChanged }) {
    const fileInput = React.createRef();

    const updateFiles = () => {
        filesChanged([...files, ...Array.from(fileInput?.current.files ? fileInput.current.files : [])])
    };

    return <span className={className} onClick={() => { fileInput?.current?.click() }}>
        <input ref={fileInput} style={{ display: 'none' }} onChange={updateFiles} type="file" multiple={true} />
        {children ? children : <i className="area-action far fa-paperclip"></i>}
    </span>
}


export function AttachmentManager({ children, className, files = [], filesChanged }) {
    const fileInput = React.createRef();

    const updateFiles = () => {
        filesChanged([...files, ...Array.from(fileInput?.current.files ? fileInput.current.files : [])])
    };

    return <span className={className} onClick={() => { fileInput?.current?.click() }}>
        <input ref={fileInput} style={{ display: 'none' }} onChange={updateFiles} type="file" multiple={true} />
        {children ? children : <i className="area-action far fa-paperclip"></i>}
    </span>
}

export function FooterSenderArea({ className, state, isSending, notesEditor, chatEditor, emailEditor }) {
    const [internalState, setState] = useState(state)
    useEffect(() => {
        setState(state)
    }, [state])

    return (
        <div className={cn("chat-footer  footer-wrap d-flex flex-column", className, { 'sending': isSending })}>
            {notesEditor && <div className='flex-grow-1' style={{ display: internalState === 'note' ? 'flex' : 'none' }}>{notesEditor.editor}</div>}
            {chatEditor && <div className='flex-grow-1' style={{ display: internalState === 'chat' ? 'flex' : 'none' }}>{chatEditor?.editor}</div>}
            {emailEditor && <div className='flex-grow-1' style={{ display: internalState === 'email' ? 'flex' : 'none' }}>{emailEditor.editor}</div>}
            <div className="send-area d-flex">
                {internalState === 'note' && notesEditor?.buttons}
                {internalState === 'chat' && chatEditor?.buttons}
                {internalState === 'email' && emailEditor?.buttons}
            </div >
        </div >
    )
}


class ChatInputFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            main: "",
            tranlsation: "",
            note: "",
            isWaitingForTranslation: false,
            isSendingMessage: false,
            uploadFiles: [],
            mode: "",
            emojiPickerOpen: false,
        }

        this.fileInput = React.createRef();
        this.changeMainInput = this.changeMainInput.bind(this);
        this.changeTranslateInput = this.changeTranslateInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isTranslation = this.isTranslation.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.removeFileFromList = this.removeFileFromList.bind(this);
        this.onQRSelect = this.onQRSelect.bind(this);
        this.switchInputMode = this.switchInputMode.bind(this);
        this.sendPrivateNote = this.sendPrivateNote.bind(this);
        this.setEmojiPickerState = this.setEmojiPickerState.bind(this);

        this.delayedTranslation = debounce(((input) => {
            if (!input) {
                this.setState({ tranlsation: "", isWaitingForTranslation: false });
                return
            }

            let data = {
                toLang: this.props.activeChat.contact.visitorLang,
                fromLang: this.props.activeChat.contact.supportLang,
                messageContent: input,
            }

            this.setState({ isWaitingForTranslation: true });
            wsEventManager.sendEventWithCB({ eventType: "CHAT:TRANSLATE", translate: data }).then(result => {
                this.setState({ tranlsation: htmlDecode(result.response), isWaitingForTranslation: false });
            }).catch(() => {
                this.setState({ isWaitingForTranslation: false });
            })
        }), 800);

        this.delayedStoppedWriting = debounce((() => {
            wsEventManager.sendEvent({ eventType: "CHAT:WRITING_STOP", chat: { contactID: this.props.activeChat.activeContactID, sourceType: this.props.activeChat.contact.sourceType } })
        }), 4000);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeChat.activeContactID !== this.props.activeChat.activeContactID) {
            this.setState({
                main: "",
                tranlsation: "",
                uploadFiles: [],
                isSendingMessage: false,
            });
        }
    }

    forceUpdateHandler() {
        this.setState({ uploadFiles: Array.from(this.fileInput?.current.files ? this.fileInput.current.files : []) })
    };

    removeFileFromList(path) {
        let newArr = this.state.uploadFiles.filter(el => el.name !== path);
        if (newArr.length === 0) {
            this.fileInput.current.value = ''
        }

        this.setState({ uploadFiles: newArr })
    };

    onInputPaste = e => {
        var items = e.clipboardData.items;
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (item.type.indexOf("image") !== -1) {
                e.preventDefault();
                var file = item.getAsFile();
                this.setState({ uploadFiles: [file] })
                return;
            }
        }
    }

    changeMainInput(e, appendText) {
        let newValue = ""
        if (e) {
            newValue = e.target.value
        } else if (appendText) {
            newValue = this.state.main + appendText
        }

        if (this.isTranslation() && newValue.endsWith(' ')) {
            let data = {
                toLang: this.props.activeChat.contact.visitorLang,
                fromLang: this.props.activeChat.contact.supportLang,
                messageContent: newValue,
            }

            this.setState({ isWaitingForTranslation: true });
            wsEventManager.sendEventWithCB({ eventType: "CHAT:TRANSLATE", translate: data }).then(result => {
                this.setState({ tranlsation: htmlDecode(result.response), isWaitingForTranslation: false });
            }).catch(() => {
                this.setState({ isWaitingForTranslation: false });
            })
        }

        if (this.isTranslation()) this.delayedTranslation(newValue)
        this.setState({ main: newValue });
    }

    isTranslation() { return this.props.activeChat.contact.visitorLang !== this.props.activeChat.contact.supportLang }

    changeTranslateInput(e) {
        this.setState({ tranlsation: e.target.value });
    }

    handleSubmit(e) {
        if (e) e.preventDefault();
        if (this.state.mode === 'note')
            return

        const { t } = this.props
        if ((!this.state.main && this.state.uploadFiles.length === 0) ||
            this.state.isSendingMessage ||
            this.state.isWaitingForTranslation ||
            this.delayedTranslation.isPending())
            return

        this.delayedStoppedWriting.clear();

        /*
        let attachments = this.state.tranlsation ? [{attachmentType: "TRANSLATION", translationText: this.state.tranlsation }] : null;
            this.props.dispatch(sendMessage(this.props.activeChat.activeContactID, this.props.activeChat.contact.sourceType, this.state.main, attachments))
            */

        const formData = new FormData();
        if (this.state.uploadFiles && this.state.uploadFiles.length > 0) {
            for (var i = 0; i < this.state.uploadFiles.length; i++) {
                formData.append("files", this.state.uploadFiles[i]);
            }
        }

        formData.append("contactID", this.props.activeChat.activeContactID);
        formData.append("message", this.state.main);
        formData.append("translation", this.state.tranlsation);

        apiSendMessage(formData).then(r => {
            if (this.fileInput?.current.value)
                this.fileInput.current.value = ''

            this.setState({
                main: "",
                tranlsation: "",
                uploadFiles: [],
                isSendingMessage: false,
            });

        }).catch(e => {
            this.setState({ isSendingMessage: false });
            console.error(e)
            if (e.statusCode === "FILE_TO_LARGE") {
                addErrorToast(t("Attachments to large"))
            } else if (e.statusCode === "FB_ACCESS_TOKEN_EXP") {
                addErrorToast(t("Facebook token revoked. Reconnect page"))
            } else if (e.statusCode === "FB_APP_NOT_INSTALLED") {
                addErrorToast(t("Facebook token revoked. Reconnect page"))
            } else if (e.statusCode === "FB_PW_CHANGED") {
                addErrorToast(t("Facebook token revoked. Reconnect page"))
            } else {
                addErrorToast(t("Could not send message"))
            }
        })
        this.setState({ isSendingMessage: true });
    }

    onQRSelect(qr) {
        let main = qr.value?.[this.props.activeChat.contact?.supportLang];
        if (!main) {
            //Find
        }

        if (this.isTranslation()) {
            let tranlsation = qr.value?.[this.props.activeChat.contact?.visitorLang]
            if (!tranlsation && main) {
                let data = {
                    toLang: this.props.activeChat.contact.visitorLang,
                    fromLang: this.props.activeChat.contact.supportLang,
                    messageContent: main,
                }

                wsEventManager.sendEventWithCB({ eventType: "CHAT:TRANSLATE", translate: data }).then(result => {
                    this.setState({ tranlsation: htmlDecode(result.response), isWaitingForTranslation: false });
                }).catch(() => {
                    this.setState({ isWaitingForTranslation: false });
                })
            }

            this.setState({ tranlsation: (tranlsation ? tranlsation : ""), main })
        } else {
            this.setState({ main, tranlsation: "" })
        }
    }

    changeNoteText(e) {
        this.setState({ note: e.target.value })
    }

    sendPrivateNote() {
        const { t } = this.props

        if (this.state.note)
            this.setState({ isSendingMessage: true }, () => {
                sendMessageNote({ contactID: this.props.activeChat.activeContactID, message: this.state.note })
                    .catch(() => {
                        addErrorToast(t("Could not send note"))
                    }).finally(() => {
                        this.setState({ isSendingMessage: false, note: "", mode: "chat" })
                    })
            });
    }

    switchInputMode(mode) {
        this.setState({ mode })
    }

    setEmojiPickerState(isOpen) {
        this.setState({ emojiPickerOpen: isOpen })
    }

    render() {
        let pendingFiles = this.state.uploadFiles.map((file, i) => {
            let name = file.name.split(/(\\|\/)/g).pop();
            let icon = file.type.includes("image") ? <i className="file-ico far fa-file-image"></i> : <i className="file-ico far fa-file-alt"></i>
            return <span onClick={() => { this.removeFileFromList(file.name) }} key={file.name}>{icon}{name}<i className="close-ico far fa-times"></i></span>
        })

        let isDualInput = (!this.props.onlySingle && this.props.activeChat.contact.visitorLang !== this.props.activeChat.contact.supportLang)
        return (
            <div className={cn("chat-footer", { 'dual-input': isDualInput, 'sending': this.state.isSendingMessage, 'note-mode': this.state.mode === 'note' })}>
                {pendingFiles?.length > 0 && <div className="selected-files">{pendingFiles}</div>}
                <form onSubmit={() => { }}>
                    <InputArea
                        isHidden={this.state.mode === 'note'}
                        onlySingle={!isDualInput}
                        submitText={this.handleSubmit}
                        hasUnseen={this.props.activeChat.unreadMessages && this.props.activeChat.messages.length}
                        sendSeen={() => {
                            let lastMessage = this.props.activeChat.messages[this.props.activeChat.messages.length - 1]
                            this.props.dispatch(setChatSeen(this.props.activeChat.activeContactID, this.props.activeChat.contact.sourceType, lastMessage.createdAt))
                        }}
                        onInputPaste={this.onInputPaste}
                        visitorLang={this.props.activeChat.contact.visitorLang}
                        supportLang={this.props.activeChat.contact.supportLang}
                        tranlsationText={this.state.tranlsation}
                        mainText={this.state.main}
                        changeTranslateInput={this.changeTranslateInput}
                        changeMainInput={this.changeMainInput}
                        activeChat={this.props.activeChat}
                    />
                    <NotesArea
                        isHidden={this.state.mode !== 'note'}
                        onChange={(e) => { this.changeNoteText(e) }}
                        note={this.state.note}
                        submitNotes={this.sendPrivateNote}
                    />
                    <EmojiPickerPopup
                        addEmoji={(emojiData) => {
                            if (this.state.mode === 'note') {
                                this.setState({ note: this.state.note + emojiData.native })
                            } else {
                                this.changeMainInput(null, emojiData.native)
                            }
                        }}
                        isOpen={this.state.emojiPickerOpen}
                        setOpen={this.setEmojiPickerState}
                    />
                </form>
                <SendArea
                    currentLanguage={this.props.activeChat.contact.supportLang}
                    onQRSelect={this.onQRSelect}
                    fileInput={this.fileInput}
                    submit={this.handleSubmit}
                    sendPrivateNote={this.sendPrivateNote}
                    forceUpdateHandler={this.forceUpdateHandler}
                    switchInputMode={this.switchInputMode}
                    mode={this.state.mode}
                    emojiPickerOpen={this.state.emojiPickerOpen}
                    setEmojiPickerState={this.setEmojiPickerState}
                />
            </div>)

    }
}


const mapStateToProps = state => ({ user: state.user, activeChat: state.activeChat });
const ChatInputFooterWithProps = withTranslation("chat")(connect(mapStateToProps)(ChatInputFooter))

function EmojiPickerPopup({ addEmoji, isOpen, setOpen }) {
    const wrapperRef = useRef(null);
    const { t } = useTranslation('chat')
    useOutsideClick(wrapperRef, () => {
        if (isOpen)
            setOpen(false)
    })

    if (!isOpen)
        return null

    return (<div ref={wrapperRef} className='emoji-picker-popup-wrap'>
        <EmojiPicker
            i18n={{
                "search": t("Search"),
                "search_no_results_1": "Oh no!",
                "search_no_results_2": "That emoji couldn’t be found",
                "pick": "Pick an emoji…",
                "add_custom": "Add custom emoji",
                "categories": {
                    "activity": "Activity",
                    "custom": "Custom",
                    "flags": "Flags",
                    "foods": "Food & Drink",
                    "frequent": "Frequently used",
                    "nature": "Animals & Nature",
                    "objects": "Objects",
                    "people": t("Smileys & People"),
                    "places": "Travel & Places",
                    "search": "Search Results",
                    "symbols": "Symbols"
                },
                "skins": {
                    "choose": "Choose default skin tone",
                    "1": "Default",
                    "2": "Light",
                    "3": "Medium-Light",
                    "4": "Medium",
                    "5": "Medium-Dark",
                    "6": "Dark"
                }
            }}
            data={emojiData}
            categories={['people']}
            previewPosition={'none'}
            skinTonePosition={'none'}
            noCountryFlags={true}
            onEmojiSelect={addEmoji}
        />
    </div>)
}

function InputArea({ isHidden, onlySingle, submitText, activeChat, hasUnseen, sendSeen, onInputPaste, visitorLang, supportLang, tranlsationText, mainText, changeTranslateInput, changeMainInput }) {
    const { t } = useTranslation('chat');
    const text1 = useRef(null);
    const text2 = useRef(null);
    const lastValues = useRef({})

    useEffect(() => {
        if (lastValues?.current?.tranlsationText !== tranlsationText) {
            if (text2?.current) {
                text2.current.style.height = 'auto';
                text2.current.style.height = (text2.current.scrollHeight) + 'px'; // Adding  pixel for border
            }
        }

        if (lastValues?.current?.mainText !== mainText) {
            if (text1?.current) {
                text1.current.style.height = 'auto';
                text1.current.style.height = (text1.current.scrollHeight) + 'px'; // Adding  pixel for border
            }
        }

        lastValues.current = { tranlsationText, mainText }
    }, [tranlsationText, mainText])

    const delayedStoppedWriting = useMemo(() => debounce((() => {
        wsEventManager.sendEvent({ eventType: "CHAT:WRITING_STOP", chat: { contactID: activeChat.activeContactID, sourceType: activeChat.contact.sourceType } })
    }), 4000), [activeChat]);

    const textAreaKeypress = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            submitText();
        } else {
            if (!delayedStoppedWriting.isPending())
                wsEventManager.sendEvent({ eventType: "CHAT:WRITING_START", chat: { contactID: activeChat.activeContactID, sourceType: activeChat.contact.sourceType } })

            if (hasUnseen) {
                sendSeen()
            }

            delayedStoppedWriting()
        }
    }

    if (isHidden)
        return null

    if (onlySingle)
        return (
            <div className="input-wrap">
                <textarea ref={text1} spellCheck="true" type="text" placeholder={t("Start a conversation")} rows="1" value={mainText} onChange={changeMainInput} onPaste={onInputPaste} onKeyPress={textAreaKeypress} />
            </div>
        )

    return (
        <div className="input-wrap">
            <div className="language-input">
                <div className="language-switch">
                    {getLanguage(visitorLang)?.flag_iso && <div className='flag-wrap'><ReactCountryFlag svg style={{
                        height: 16,
                        width: 22,
                        borderRadius: 2,
                        objectFit: 'cover',
                    }} countryCode={getLanguage(visitorLang)?.flag_iso} /></div>}
                    <div className='lang-text'>{visitorLang}</div>
                </div>
                <div className='translation-wrap'>
                    {/*<div className='edit-translation'>Do you want to edit translation ?</div>*/}
                    <textarea ref={text2} type="text" style={{ background: '#f5f6f8' }} placeholder={t("Enter translation")} rows="1" value={tranlsationText} onChange={changeTranslateInput} onPaste={onInputPaste} onKeyPress={textAreaKeypress} />
                </div>
            </div>
            <div className="language-input">
                <div className="language-switch">
                    {getLanguage(supportLang)?.flag_iso &&
                        <div className='flag-wrap'><ReactCountryFlag svg style={{
                            height: 16,
                            width: 22,
                            borderRadius: 2,
                            objectFit: 'cover',
                        }} countryCode={getLanguage(supportLang)?.flag_iso} />
                        </div>}
                    <div className='lang-text'>{supportLang}</div>

                </div>
                <textarea ref={text1} spellCheck="true" type="text" placeholder={t("Start a conversation")} rows="1" value={mainText} onChange={changeMainInput} onPaste={onInputPaste} onKeyPress={textAreaKeypress} />
            </div>
        </div>
    )
}

function SendArea({ currentLanguage, onQRSelect, sendPrivateNote, emojiPickerOpen, fileInput, setEmojiPickerState, submit, forceUpdateHandler, switchInputMode, mode }) {
    const { t } = useTranslation('chat');

    if (mode === 'note') {
        return (
            <div className="send-area">
                <ThinButton type="dark" className="" onClick={() => { switchInputMode('chat') }}>
                    {t("Back", { ns: 'common' })}
                    <i className="far fa-arrow-left" ></i>
                </ThinButton>
                {false && <SeparatorVertical />}
                {false && <i className={cn("area-action fa-smile", { 'far': !emojiPickerOpen, 'fas': emojiPickerOpen, 'is-open': emojiPickerOpen })} onClick={() => { setEmojiPickerState(true) }}></i>}
                <ThinButton type="primary" className="ml-auto position-relative" onClick={sendPrivateNote}>
                    <span className='send-text-wrap'>
                        {t("Send")}
                        <i className="fas fa-paper-plane" ></i>
                    </span>
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </ThinButton>
            </div >
        )
    }

    return (
        <div className="send-area">
            <ThinButton type="secondary" className="note-btn" onClick={() => { switchInputMode('note') }}>
                {t("Private note")}
                <i className="far fa-comment-alt-lines" ></i>
            </ThinButton>
            <SeparatorVertical />
            <QuickReplyToggle currentLanguage={currentLanguage} onQRSelect={onQRSelect} />
            <i className="area-action far fa-paperclip" onClick={() => { fileInput?.current?.click() }}></i>
            {false && <i className={cn("area-action fa-smile", { 'far': !emojiPickerOpen, 'fas': emojiPickerOpen, 'is-open': emojiPickerOpen })} onClick={() => { setEmojiPickerState(true) }}></i>}            <ThinButton type="primary" className="ml-auto position-relative" onClick={submit}>
                <span className='send-text-wrap'>
                    {t("Send")}
                    <i className="fas fa-paper-plane" ></i>
                </span>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </ThinButton>
            <input ref={fileInput} onChange={forceUpdateHandler} type="file" multiple={true} />
        </div>
    )
}



function ChatAcceptFooter(params) {
    const userDetails = useSelector(state => state.user.userDetails)
    const [selectedLang, setSelectedLang] = useState(getInitalSelectedLang())
    const { t } = useTranslation("chat")
    const fallbackLanguages = ["en", "et", "ru", "fi"]
    let foundLangs = [];
    if (userDetails.languages && userDetails.languages.length > 0) {
        foundLangs = userDetails.languages
    } else {
        foundLangs.push(params.visitorLang.iso, ...fallbackLanguages)
    }

    foundLangs = [...new Set(foundLangs)]; // TODO BETTER
    let quickPicks = foundLangs.map(el => ({ label: getLanguage(el).eng, value: el }))

    useEffect(() => {
        setSelectedLang(getInitalSelectedLang())
    }, [params.visitorLang])

    function getInitalSelectedLang() {
        if (userDetails.languages && userDetails.languages.length > 0) {
            let foundLang = userDetails.languages.find(l => params.visitorLang.iso === l)
            if (foundLang) {
                return foundLang
            }

            if (userDetails.languages[0])
                return userDetails.languages[0]
        }

        return "en"
    }

    let showLanguages = params?.activeChat?.contact?.sourceType !== "EMAIL"


    return (
        <div className='start-conversation-wrap'>
            <Separator>
                {!showLanguages && t("Chat is waiting for you")}
                {showLanguages && t("Choose the language and reply")}
            </Separator>
            <div className='extra-actions'>
                {showLanguages && <SearchDropdownWithLabelPrefix leftAlign topAlign className="mb-0" menuWidth={220} label={t("Visitor")} currentSelect={params.visitorLang.eng} allSelection={getLanguages()} onSelect={params.changeVisiorLanguage} />}
                {showLanguages && <SearchDropdownWithQuickPicks
                    topAlign prefixRight
                    className="ml-auto mb-0"
                    label={t("Me")}
                    menuWidth={220}
                    valueKey="value"
                    labelKey="label"
                    quickPicks={quickPicks}
                    currentValue={selectedLang}
                    currentSelect={t("Other")}
                    allSelection={getLanguagesLabelValue()}
                    onSelect={setSelectedLang} />}
            </div>
            <div className='language-select-wrap'>
                <div style={{ minWidth: 1, flex: 1 }}></div>
                <div className="d-flex" style={{ minWidth: 1, flex: 1 }}>
                    <div style={{ position: 'relative', cursor: 'pointer', margin: 'auto', color: "#fff", background: "#9686f2", padding: "6px 16px", borderRadius: 6 }} onClick={() => { params.onClick(selectedLang) }}>
                        {showLanguages && <div style={{
                            position: "absolute",
                            color: "rgb(136, 152, 183)",
                            fontSize: 12,
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            transform: "translate(-50%, 0px)",
                            inset: "auto auto calc(100% + 2px) 50%",
                        }}>{params.visitorLang.eng} to {getLanguage(selectedLang, "iso").eng}</div>}
                        {t("Take over the chat")}
                    </div>
                </div>
                <div className="d-flex" style={{ minWidth: 1, flex: 1 }}>
                    <ThinButton type="secondary" className="ml-auto" onClick={params.archive}><i className="mx-1 far fa-flag-checkered"></i>{t("Archive")}</ThinButton>
                    <ThinButton type="secondary" className="ml-2" onClick={params.askToBlock}><i className="mx-1 far fa-ban"></i>{t("Block")}</ThinButton>
                </div>
            </div>
        </div>
    )
}


function ArchivedFooter(params) {
    const { t } = useTranslation("chat")

    return (
        <div className='start-conversation-wrap'>
            <Separator>
                {!params.isBlocked && <span> {t("Chat has been archived")}</span>}
                {params.isBlocked && <span> {t("Chat has been blocked")}</span>}
            </Separator>
            <div className='extra-actions mb-0'>
                {!params.isBlocked && <ThinButton type="secondary" className="mx-auto" onClick={params.reopenChat}>{t("Reopen chat")}</ThinButton>}
            </div>
        </div >
    )
}

function NotAssignedFooter(params) {
    const { t } = useTranslation("chat")
    const assignedToAssistant = params.status === "askly_assistant"
    return (
        <div className='start-conversation-wrap'>
            <Separator>
                <span>
                    <span>{t("Chat is assigned to {{name}}", { name: assignedToAssistant ? t("AI Assistant") : params.assignedUser?.name })}</span><br></br>
                    <span>
                        <Trans
                            t={t}
                            defaults="Press <i>'Take over the chat'</i> to take control of the chat"
                        />
                    </span>
                </span>
            </Separator>
            <div className='extra-actions mb-0'>
                <SearchDropdownWithLabelPrefix topAlign disabled className="mb-0" menuWidth={220} label={t("Customer language")} currentSelect={params.visitorLang.eng} allSelection={getLanguages()} onSelect={() => { }} />
                <ThinButton type="secondary" className="ml-auto" onClick={params.onClick}>{t("Take over the chat")}</ThinButton>
            </div>
        </div >
    )
}
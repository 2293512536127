import { default as classNames, default as cn } from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Highlighter from "react-highlight-words"
import { useTranslation } from "react-i18next"
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { b64ToPastelColor, toFormatedDateString } from '../../logic/util'
import emailIcon from '../../resources/email_icon.svg'
import instagramIcon from '../../resources/insta_icon_240x240.png'
import messengerIcon from '../../resources/messenger.svg'
import noChats from '../../resources/no-chats.png'
import { fetchChatData } from '../../state/activeChat'
import { fetchChatList, fetchNextChatList } from '../../state/chatList'
import './ChatsList.scss'
import { Separator } from './minor/Common'
import EllipsisLoading from './minor/EllipsisLoading'
import Tag from './minor/Tag'
import useOutsideClick from './useOutsideClick'

export default function ChatsList(params) {
    const dispatch = useDispatch();
    const chatsWrap = useSelector(state => state.chatList)
    const { scrollToMessageID } = useSelector(state => state.activeChat)

    let chatArr = chatsWrap.chats ? chatsWrap.chats.map((el, i) => (
        <ChatElement isArchived={chatsWrap.activeFilter === "archived"} key={el.messageID} data={el} isActive={(scrollToMessageID === el.messageID)} higlightedTerms={chatsWrap?.textFilter} />
    )) : null;

    const fetchData = (params) => {
        dispatch(fetchNextChatList())
    }

    let activePart = (chatsWrap.termFilter || chatsWrap.textFilter || chatsWrap.termFilter || chatsWrap.tagFilter?.length) ? "search" : chatsWrap.activeFilter
    let searchMode = activePart === "search"

    return (
        <div className={cn("chat-list")}>
            <ChatTypeSwitch  {...chatsWrap} activePart={activePart} sourceFilter={chatsWrap.sourceFilter} />
            <div id="chat-main-list" className="chat-list-scroll">
                {!chatsWrap.loading && chatArr.length === 0 && <NoChatsPH />}
                <InfiniteScroll
                    dataLength={chatArr.length}
                    next={fetchData}
                    hasMore={chatsWrap.hasMore} // Replace with a condition based on your data source
                    loader={<EllipsisLoading className="chat-list-loader" />}
                    scrollableTarget="chat-main-list"
                >
                    {chatArr}
                </InfiniteScroll>
            </div>
        </div>
    )
}


function NoChatsPH(params) {
    const { t } = useTranslation("chat")
    return (
        <figure className="mx-auto">
            <img className="default" src={noChats} alt="No chats" />
            <figcaption className="caption">
                <p>{t("New chats will be here.")}</p>
            </figcaption>
        </figure>
    )
}

function ChatTypeSwitch(params) {
    const dispatch = useDispatch();
    const { t } = useTranslation("chat")
    const siteDetails = useSelector(state => state.user?.siteDetails)
    let features = useMemo(() => ({
        hasEmailFeature: siteDetails?.siteConfig?.features?.includes?.("AsklyEmails"),
        hasAssistantFeature: siteDetails?.siteConfig?.features?.includes?.("AsklyAssistant"),
    }), [siteDetails])

    let sourceNotifications = useMemo(() => {
        return params.allNotifications?.reduce((acc, curr) => {
            if (!curr)
                return acc

            let sourceType = curr.source
            if (sourceType === "FB" || sourceType === "INSTAGRAM") {
                sourceType = "SOCIALS"
            }

            if (acc?.[sourceType]) {
                acc[sourceType].push(curr)
            } else {
                acc[sourceType] = [curr]
            }

            if (params.sourceFilter === "all" || params.sourceFilter?.toLowerCase() === sourceType?.toLowerCase()) {
                if (curr?.status === "assigned") {
                    if (acc.me)
                        acc.me.push(curr)
                    else
                        acc.me = [curr]
                } else if (curr?.status === "pending") {
                    if (acc.new)
                        acc.new.push(curr)
                    else
                        acc.new = [curr]
                }
            }

            return acc
        }, {})
    }, [params.allNotifications, params.sourceFilter])


    function changeFilter(activeFilter, tagFilter, termFilter, textFilter, sourceFilter) {
        dispatch(fetchChatList({ activeFilter, tagFilter, termFilter, textFilter, sourceFilter }))
    }

    let activePart = params.activePart;

    return (
        <div className='chat-list-filters-wrap tour-step-chat-filter'>
            <SearchPopout
                active={activePart === "search"}
                initTermFilter={params.termFilter}
                initTextFilter={params.textFilter}
                initTagFilter={params.tagFilter}
                allTags={siteDetails?.allTags}
                changeFilter={changeFilter}
            />
            {activePart !== "search" &&
                <div className='chat-list-sources'>
                    <div className={cn('source-filter', { active: !params.sourceFilter || params.sourceFilter === 'all', notificationbadge: (params.allNotifications?.length > 0) })} onClick={() => { changeFilter(params.activePart, null, null, null, 'all') }} >All</div>
                    <div className={cn('source-filter', { active: params.sourceFilter === 'web', notificationbadge: (sourceNotifications?.['WEB']?.length > 0) })} onClick={() => { changeFilter(params.activePart, null, null, null, 'web') }}>Web</div>
                    <div className={cn('source-filter', { active: params.sourceFilter === 'socials', notificationbadge: (sourceNotifications?.['SOCIALS']?.length > 0) })} onClick={() => { changeFilter(params.activePart, null, null, null, 'socials') }}>Socials</div>
                    {features.hasEmailFeature && <div className={cn('source-filter', { active: params.sourceFilter === 'email', notificationbadge: (sourceNotifications?.['EMAIL']?.length > 0) })} onClick={() => { changeFilter(params.activePart, null, null, null, 'email') }}> Email</div >}
                </div >
            }
            {
                activePart !== "search" && <div className='chat-list-presets'>
                    <ChatFilterElem text={t("New")} alertNew active={activePart === "inbox"} hasUnreads={sourceNotifications?.new?.length > 0} count={params.inboxCount > 99 ? "99+" : params.inboxCount} onClick={() => { changeFilter("inbox", null, null, null, params.sourceFilter) }} />
                    <ChatFilterElem text={t("My chats")} active={activePart === "me"} hasUnreads={sourceNotifications?.me?.length > 0} count={params.meCount > 99 ? "99+" : params.meCount} onClick={() => { changeFilter("me", null, null, null, params.sourceFilter) }} />
                    <ChatFilterElem text={t("Team chats")} active={activePart === "others"} hasUnreads={false} count={params.othersCount > 99 ? "99+" : params.othersCount} onClick={() => { changeFilter("others", null, null, null, params.sourceFilter) }} />
                    {features.hasAssistantFeature && <ChatFilterElem text={t("AI Assistant")} active={activePart === "askly_assistant"} hasUnreads={false} onClick={() => { changeFilter("askly_assistant", null, null, null, params.sourceFilter) }} />}
                    <ChatFilterElem text={t("All")} active={activePart === "all"} onClick={() => { changeFilter("all", null, null, null, params.sourceFilter) }} />
                    <ChatFilterElem text={t("Archived")} active={activePart === "archived"} onClick={() => { changeFilter("archived", null, null, null, params.sourceFilter) }} />
                </div>
            }
            <Separator>
                {activePart === "inbox" && <span>{t("New chats")}</span>}
                {activePart === "me" && <span>{t("My chats")}</span>}
                {activePart === "all" && <span>{t("All chats")}</span>}
                {activePart === "others" && <span>{t("Team chats")}</span>}
                {activePart === "askly_assistant" && <span>{t("AI Assistant")}</span>}
                {activePart === "archived" && <span>{t("Archived chats")}</span>}
                {activePart === "search" && <span>{t("Search results")}</span>}
            </Separator>
        </div >
    )
}

function SearchPopout({ active, changeFilter, allTags = [], initTagFilter = [], initTermFilter = "", initTextFilter = "" }) {
    const wrapperRef = useRef(null);
    const [show, setShow] = useState(false)
    const [tagsNameFilter, setTagsNameFilter] = useState("")
    const [chatFilter, setChatFilter] = useState({
        activeFilter: "all",
        sourceFilter: "",
        termFilter: initTermFilter,
        textFilter: initTextFilter,
        tagFilter: initTagFilter,
    })
    const { t } = useTranslation("chat")


    function inputTermChangeAndSearch(e) {
        setChatFilter({ ...chatFilter, termFilter: e.target.value })
    }

    function inputTextChangeAndSearch(e) {
        setChatFilter({ ...chatFilter, textFilter: e.target.value })
    }

    function removeTag(tag) {
        let newFilter = chatFilter.tagFilter.filter(tagID => tagID !== tag.id)
        if (!newFilter)
            newFilter = []

        setChatFilter({ ...chatFilter, tagFilter: newFilter })
    }

    function addTag(tag) {
        setChatFilter({ ...chatFilter, tagFilter: [...chatFilter.tagFilter, tag.id] })
    }

    function search() {
        changeFilter(chatFilter.activeFilter, chatFilter.tagFilter, chatFilter.termFilter, chatFilter.textFilter)
        setShow(false);
    }

    function cancelFilter(e) {
        e?.stopPropagation();

        let newFillter = {
            activeFilter: "inbox",
            sourceFilter: "",
            termFilter: "",
            textFilter: "",
            tagFilter: [],
        }

        setChatFilter(newFillter)
        changeFilter(newFillter.activeFilter, newFillter.tagFilter, newFillter.termFilter, newFillter.textFilter)
        setShow(false)
    }

    function onSubmit(e) {
        e.preventDefault();
        search()
    }

    useOutsideClick(wrapperRef, () => {
        if (show)
            setShow(false)
    })

    useEffect(() => {
        if (show) {
            wrapperRef.current.querySelector(".input-wrap input")?.focus?.()
        }
    }, [show])

    useEffect(() => {
        setChatFilter({
            activeFilter: "all",
            termFilter: initTermFilter,
            textFilter: initTextFilter,
            tagFilter: initTagFilter,
        })
    }, [initTermFilter, initTextFilter, initTagFilter])

    let tagSelection = allTags?.filter(t => !chatFilter.tagFilter.includes(t.id))
    let initSelection = allTags?.filter(t => initTagFilter.includes(t.id))
    let tags = chatFilter.tagFilter.map(tagID => allTags.find(t => t.id === tagID))
    let initBadgesElements = initSelection?.map((tag, i) => <Tag key={i} tag={tag} hideX />)
    let selectedBadgesElements = tags?.map((tag, i) => <Tag key={i} tag={tag} onClick={(e) => { e.stopPropagation(); removeTag(tag) }} />)
    let allowedBadgesElements = tagSelection.filter(t => t.name.toLowerCase().includes(tagsNameFilter?.toLowerCase()))?.map((tag, i) => (<Tag key={i} tag={tag} contactID={tag.taggedContactID} hideX={true} onClick={(e) => { e.stopPropagation(); addTag(tag) }} />))
    let searchDisplayElem = <span className='search-init'>
        {initTermFilter}
        {initTermFilter && initTextFilter && <span className='mx-1'>|</span>}
        {initTextFilter}
        {initBadgesElements}
    </span>

    return (
        <div ref={wrapperRef} className="search-toggle-wrap" onClick={() => { setShow(true) }}>
            <div className={classNames('search-toggle', { active })}>
                {!active && <span>{t("Search customers")}</span>}
                {!active && <i className="ml-auto px-2  far fa-search"></i>}
                {active && <span className='search-disp'>{searchDisplayElem}</span>}
                {active && <span className='ml-auto px-2' onClick={cancelFilter}><i className="far fa-times"></i></span>}
            </div>
            {show && <form onSubmit={onSubmit} className='search-popout-wrap'  >
                <div>
                    <div className='input-wrap'>
                        <input onChange={inputTermChangeAndSearch} value={chatFilter.termFilter} placeholder={t('Search customers')}></input>
                        <div> <i className="ml-auto  far fa-address-card"></i></div>
                    </div>
                    <div className='info-txt'>{t("Search customers by name or email")}</div>
                    <div className='input-wrap mt-3'>
                        <input onChange={inputTextChangeAndSearch} value={chatFilter.textFilter} placeholder={t('Search chats')}></input>
                        <div> <i className="ml-auto  far fa-comment-alt-lines"></i></div>
                    </div>
                    <div className='info-txt'>{t("Search in chat contents")}</div>
                    <div className='px-2 py-2'>
                        <div className='mt-2 mb-3'>
                            <div className='font-weight-bold mb-1'>{t("Filter group")}</div>
                            <SelectOneTag
                                onSelect={(val) => { setChatFilter({ ...chatFilter, activeFilter: val }) }}
                                value={chatFilter?.activeFilter}
                                tags={[
                                    { name: t('All'), value: 'all' },
                                    { name: t('New'), value: 'inbox' },
                                    { name: t('Me'), value: 'me' },
                                    { name: t('Team'), value: 'team' },
                                    { name: t('Archived'), value: 'archive' },
                                ]} />
                        </div>
                        <div className='mt-2 mb-3'>
                            <div className='font-weight-bold'>{t("Tags")}</div>
                            <div className='tags-select-wrap d-flex flex-column'>
                                <div className='tag-input-wrap mb-2'>
                                    {selectedBadgesElements}
                                    <input value={tagsNameFilter} onChange={(e) => setTagsNameFilter(e.target.value)} placeholder={t('Filter by tags', { ns: 'tags' })}></input>
                                </div>
                                <div className='tags-wrap' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', maxHeight: 90, overflow: 'auto' }}>
                                    {allowedBadgesElements}
                                    {allowedBadgesElements?.length === 0 && <span>{t("No tags found", { ns: 'tags' })}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex mt-2'>
                            <button type='button' className='ml-auto mr-2 sml-btn sml-secondary' onClick={cancelFilter}>{t("Cancel", { ns: 'common' })}</button>
                            <button type='submit' className="sml-btn" onClick={search} disabled={(!chatFilter.textFilter && !chatFilter.termFilter && !chatFilter.tagFilter?.length)}>
                                {t("Search", { ns: 'common' })}
                            </button>
                        </div>
                    </div>
                </div>
            </form>}
        </div>


    )
}

function SelectOneTag({ tags, value, onSelect }) {
    return (
        <div className='select-one-wrap d-flex flex-wrap justify-content-around'>
            {tags.map(t =>
                <div key={t.value} className={classNames("selectable-tag", { 'active': (t.value === value) })}
                    onClick={() => { onSelect(t.value) }}>
                    {t.name}
                </div>)}
        </div>
    )
}
function ChatFilterElem({ text, count, active, alertNew, hasUnreads, onClick }) {
    return (
        <div className={classNames("chat-filter-elem", { active, 'alert-new': alertNew && count })} onClick={onClick}>
            <div className='filter-name'>{text}</div>
            {count !== undefined && <div className={classNames('filter-count', { 'unreads': hasUnreads })}>{count}</div>}
        </div>
    )
}
function ChatElement(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation("chat")

    const chatClick = () => {
        dispatch(fetchChatData(props.data.id, { scrollToMessageID: props.data.messageID }))
    }

    const bgColor = b64ToPastelColor(props.data.searchID)
    const displayName = props.data.name ? props.data.name : (props.data.email ? props.data.email : ("#" + props.data.searchID))
    const displayLetter = props.data.name ? props.data.name[0] : (props.data.email ? props.data.email[0] : props.data.searchID[0])


    let message = (props.data.isDeleted ? t("Message deleted") : props.higlightedTerms ? <Highlighter
        highlightClassName="highlighted"
        searchWords={props.higlightedTerms.split(" ")}
        autoEscape={true}
        textToHighlight={props.data.messageContent}
    /> : props.data.messageContent)

    if (!message) {
        switch (props.data.messageType) {
            case "INPUT:EMAIL":
                message = "Email request"
                break;
            case "INPUT:PHONE_NR":
                message = "Phone number request"
                break;
            default:
                message = "Attachment"
                break;
        }
    }

    return (
        <div className={props.isActive ? "chat-list-elem active" : "chat-list-elem"} onClick={chatClick} >
            <div className="chat-list-elem-upper">
                <div className="visitor-icon" style={{ background: bgColor }}>
                    <span className="visitor-letter">{displayLetter}</span>
                    {props.data.isConnected && <div className="is-online"></div>}
                    {props.data.sourceType === "FB" && <img className="fb-icon" src={messengerIcon} alt="" />}
                    {props.data.sourceType === "INSTAGRAM" && <img className="insta-icon" src={instagramIcon} alt="" />}
                    {props.data.sourceType === "EMAIL" && <img className="email-icon" src={emailIcon} alt="" />}
                </div>
                <div className="chat-list-elem-core">
                    <div className="visitor-name d-flex position-relative">
                        <span className="ellipsis" title={displayName}>{displayName}</span>
                        {props.data.unreadMessages && !props.isArchived && <div className="unread ml-auto">{props.data.unreadMessages}</div>}
                    </div>
                    <div className="last-message">
                        {!props.data.isVisitorWriting && message}
                        {props.data.isVisitorWriting && <span className="writing-wrap"><div className="writing"></div></span>}
                    </div>
                </div>
            </div>
            <div className="chat-list-elem-lower">
                {props.data?.tags?.map((tagging, i) => (<Tag key={i} tag={tagging.tag} hideX={true} contactID={tagging.taggedContactID} />))}
            </div>
            <div className="time ml-auto">{toFormatedDateString(props.data.messageTime ? props.data.messageTime : props.data.createdAt, false, false, true)}</div>
        </div>
    )
}


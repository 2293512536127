import React, { useEffect, useMemo, useReducer } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import spacetime from 'spacetime';
import { setSiteBreak } from '../../logic/api';
import { extractInitials, scheduleToOpenTimes, toFormatedDateString } from '../../logic/util';
import fullLogo from '../../resources/askly-long.svg';
import exampleInboxImg from '../../resources/example/inbox_example.png';
import { useFeatureRequestMutation } from '../../state/api';
import { changeActiveSite, fetchMyDetails } from '../../state/currentUser';
import CalendlyModal from '../modals/CalendlyModal';
import ConfirmModal from '../modals/ConfirmModal';
import { addModal } from '../modals/ModalManager';
import './HeaderBar.scss';
import { addErrorToast, addInfoToast } from './ToastManager';
import { InfoTooltip } from './minor/Tooltip';

function HeaderBar() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation("common")
    const [requestFeature, { isLoading }] = useFeatureRequestMutation()
    let features = useMemo(() => ({
        hasEmailFeature: user?.siteDetails?.siteConfig?.features?.includes?.("AsklyEmails"),
        hasAssistantFeature: user?.siteDetails?.siteConfig?.features?.includes?.("AsklyAssistant"),
    }), [user?.siteDetails])

    function switchSite(siteID) {
        dispatch(changeActiveSite(siteID))
        // window.location.href = "/api/me/switch?site=" + siteID
    }

    function logout(params) {
        window.location.href = "/api/logout"
    }

    function openAIFeatureModal(params) {
        addModal(
            <CalendlyModal />,
            {
                data: {},
                onConfirm: () => { },
                onCancel: () => { }
            },
        )
    }

    function openEmailFeatureModal(params) {
        addModal(
            <ConfirmModal />,
            {
                size: 'lg',
                data: {
                    title: t("Email Integration with Askly", { ns: 'modals' }),
                    body: <div className='d-flex'>
                        <Trans ns='modals' defaults='Askly now allows you to forward emails directly to your Askly dashboard, ensuring all communication channels are conveniently in one place.<br/><br/>This is a beta version that can be used for free.<br/>Let us know your interest, and our team will help set up your online store.' />
                        <figure style={{ width: 230, marginBottom: 0, marginLeft: 42 }}>
                            <img src={exampleInboxImg} style={{ width: 230 }} alt='chat-inbox' />
                            <figcaption style={{ fontSize: 10, textAlign: 'center' }}>{t("Askly Inbox", { 'ns': 'modals' })}</figcaption>
                        </figure>
                    </div>,
                    confirmText: t("Get access", { ns: 'modals' }),
                    cancelText: t("Back")
                },
                onConfirm: () => {
                    requestFeature({ feature: 'emails' }).unwrap().then(() => {
                        addInfoToast("Request sent")
                        dispatch(fetchMyDetails()).then(() => {
                            history.push("/settings/site/email")
                        })
                    }).catch(e => {
                        addErrorToast("Something went wrong")
                    })
                },
                onCancel: () => { }
            },
        )
    }

    let hasNotifications = false
    let currentSiteName = "";

    let sites = user?.userDetails.sites?.map(el => {
        let isCurrentSite = user?.siteDetails.id === el.siteID

        if (isCurrentSite)
            currentSiteName = el.site.name

        if (!isCurrentSite && el.unreadContacts?.length > 0)
            hasNotifications = true

        return (
            <div className="site-select" key={el.siteID}>
                <span >{el.site.name}</span>
                {isCurrentSite && <Button className="ml-3" variant="primary" disabled size="sm">{t("Opened")}</Button>}
                {!isCurrentSite && <Button onClick={() => { switchSite(el.siteID) }} className="ml-3" variant="outline-primary" size="sm">{t("Open")}</Button>}
                {!isCurrentSite && el.unreadContacts?.length > 0 && <div className="red-unread-count"></div>}
            </div>)
    });

    return (
        <div className="main-header-bar" style={{ height: "62px", display: "flex" }}>
            <div >
                <img src={fullLogo} alt="" style={{ height: "100%", width: 112, marginLeft: 24 }} />
            </div>
            <div className="ml-auto"></div>
            {(!features.hasAssistantFeature || !features.hasEmailFeature) && <div className='d-flex'>
                <div className="my-auto mr-2 position-relative" style={{ color: '#42526e' }}>
                    <Trans t={t} defaults="<b>New!</b> Work smarter and win more customers:" components={{ b: <b style={{ color: '#45c4a0' }} /> }} />
                </div>
                {!features.hasAssistantFeature && <Button className="my-auto mr-2 position-relative" variant='outline-success' onClick={openAIFeatureModal}>
                    {t("AI Assistant")}
                </Button>}
                {!features.hasEmailFeature && <Button variant='outline-success' className="my-auto mr-4 position-relative" onClick={openEmailFeatureModal}>
                    {t("Emails")}
                </Button>}
            </div>}

            <AwayDropdown siteSettingsWrap={user?.siteDetails?.siteSettingsWrap} />
            <div className="ml-5 my-auto mr-2 user-avatar">
                {extractInitials(user?.userDetails.name)}
                {hasNotifications && <div className="red-dot"></div>}
            </div>
            <Dropdown className="sites-switch my-auto">
                <Dropdown.Toggle as={CustomToggle}>
                    <div className="d-flex flex-column">
                        <div className="user-name-header ">{user?.userDetails.name}</div>
                        <div className="current-site-name">{currentSiteName}</div>
                    </div>
                    <i className="ml-2 my-auto far fa-chevron-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu align="right">
                    <div className="sites-wrap">
                        {sites}
                    </div>
                    {sites?.length > 0 && <div className="dr-down-separator"></div>}
                    {sites?.length > 0 && <a href="/setup" className="site-select dr-text-option dr-text-option ">
                        {t("Create new site")}
                    </a>}
                    <div onClick={logout} className="site-select dr-text-option">
                        {t("Log out")}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div >
    )

}

export default HeaderBar;


// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a href="#" className={"d-flex dr-toggle my-auto"} ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
        {children}
    </a>
));

const CustomAwayToggle = React.forwardRef(({ className, children, onClick }, ref) => (
    <div className={"dr-toggle my-auto away-dr-toggle"} ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
        {children}
    </div>
));

function AwayDropdown({ siteSettingsWrap }) {
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const { t } = useTranslation("common")

    let isOnBreak = new Date(siteSettingsWrap?.breakUntil) > new Date()
    let openTimesData = scheduleToOpenTimes(siteSettingsWrap?.settings?.schedules)


    useEffect(() => {
        let breakEndTimeout;
        let breakEnd = new Date(siteSettingsWrap?.breakUntil).getTime();
        let now = new Date().getTime();

        if (breakEnd > now) {
            breakEndTimeout = setTimeout(forceUpdate, breakEnd - now)
        }

        let openTimeTimeout;
        let openTimesData2 = scheduleToOpenTimes(siteSettingsWrap?.settings?.schedules)
        if (openTimesData2) {
            let nextChangeTime = (openTimesData2.isLive ? openTimesData2.nextOpen?.endTime?.toLocalDate?.() : openTimesData2.nextOpen?.startTime?.toLocalDate?.())?.getTime?.()
            if (nextChangeTime > now) {
                openTimeTimeout = setTimeout(forceUpdate, nextChangeTime - now)
            }
        }

        return () => {
            clearTimeout(breakEndTimeout)
            clearTimeout(openTimeTimeout)
        }
    }, [siteSettingsWrap])

    function setBreak(breakUntil) {
        setSiteBreak(breakUntil).then(() => {

        }).catch(() => {

        })
    }


    let backInTime
    if (isOnBreak) {
        backInTime = new Date(siteSettingsWrap?.breakUntil)
    }

    return (
        <Dropdown className="my-auto">
            <Dropdown.Toggle as={CustomAwayToggle}>
                <i className="fal fa-clock mr-2 position-relative">
                    <div className={"status-bubble " + ((isOnBreak || openTimesData?.isLive === false) ? "is-away" : "")}></div>
                </i>
                {isOnBreak ? t("Away") : (openTimesData ? (openTimesData.isLive ? t("Online") : t("Off-hour")) : t("Online"))}
            </Dropdown.Toggle>

            <Dropdown.Menu className="px-3 py-3 away-container" style={{ minWidth: 240 }} align="right">
                <h6 className="mt-1 d-flex">
                    {t("Take timed break")}
                    <InfoTooltip className="ml-auto">
                        {t("Inform your visitors, that you're away and when you'll be active again.")}
                    </InfoTooltip></h6>
                <div className="d-flex justify-content-between mb-1 flex-wrap">
                    <Button onClick={() => { setBreak(spacetime.now().add(15, 'minute').toLocalDate()) }} className=" away-btn mb-2" >{t("{{count}} min", { count: 15 })}</Button>
                    <Button onClick={() => { setBreak(spacetime.now().add(30, 'minute').toLocalDate()) }} className=" away-btn mb-2" >{t("{{count}} min", { count: 30 })}</Button>
                    <Button onClick={() => { setBreak(spacetime.now().add(60, 'minute').toLocalDate()) }} className=" away-btn mb-2" >{t("{{count}} hour", { count: 1 })}</Button>
                    <Button onClick={() => { setBreak(spacetime.now().add(90, 'minute').toLocalDate()) }} className=" away-btn mb-2" >{t("{{count}} hour", { count: 1.5 })}</Button>
                    <Button onClick={() => { setBreak(spacetime.now().add(24, 'hour').toLocalDate()) }} className=" away-btn mb-2" >{t("{{count}} hour", { count: 24 })}</Button>
                </div>

                {isOnBreak && <div className="d-flex flex-column">
                    <h6 className="mt-3">{t("End break")}</h6>
                    <Button onClick={() => { setBreak() }} className=" away-btn mx-auto m2-2" >{t("End break")}</Button>
                    {backInTime && <div className="break-end-info mt-2"> {t("Break ending")} | {toFormatedDateString(backInTime, false, true)}</div>}
                </div>}
            </Dropdown.Menu>
        </Dropdown>
    )
}

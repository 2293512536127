import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { Redirect, Route, Switch } from "react-router-dom";
import { fetchMyDetails } from '../../state/currentUser';
import { addErrorToast } from '../base/ToastManager';
import { openTour } from '../base/TourManager';
import DefaultLayout from './DefaultLayout';
import './SettingsPage.scss';
import AsklyAssistant from './settings/AsklyAssistant';
import { ChatSettings } from './settings/ChatWidget';
import Email from './settings/Email';
import SiteMembers from "./settings/Members";
import Personal from './settings/Personal';
import { SettingsNavigation } from './settings/SettingNavigation';
import Subscription from './settings/Subscription';
import TeamSettings from './settings/Team';


class SettingsPage extends React.Component {
    componentDidMount() {
        if (this.props.location?.state?.runTour) {
            openTour()
        }


        this.props.dispatch(fetchMyDetails())
        const { t } = this.props;
        let e = new URLSearchParams(this.props.location.search).get("e")
        // TODO Remove url param e
        if (e) {
            switch (atob(e)) {
                case "FETCH_ONLY_1_ACC_FAIL":
                    addErrorToast(t("Please select 1 page to manage"))
                    break;
                default:
                    addErrorToast(t("Something went wrong", { ns: 'common' }))
                    break;
            }
        }
    }

    render() {
        let isLoaded = this.props.user?.isLoaded && this.props.user?.isSiteLoaded
        let isAdminRights = ["OWNER", "ADMIN"].includes(this.props.user?.userDetails?.currentSiteRole)
        let isPersonalSettings = matchPath(this.props.location.pathname, { path: '/settings/personal', exact: true })
        let isSiteSettings = matchPath(this.props.location.pathname, { path: '/settings/site', exact: true })
        let isWebWidget = matchPath(this.props.location.pathname, { path: '/settings/site/web-widget', exact: true })
        let isMembersPage = matchPath(this.props.location.pathname, { path: '/settings/site/users', exact: true })
        let isBillingPage = matchPath(this.props.location.pathname, { path: '/settings/subscription', exact: true })
        let isAsklyAssistantPage = matchPath(this.props.location.pathname, { path: '/settings/site/assistant', exact: true })
        let isEmailPage = matchPath(this.props.location.pathname, { path: '/settings/site/email', exact: true })
        let canSave = ((isWebWidget && isAdminRights) || (isSiteSettings && isAdminRights) || isPersonalSettings)
        let hasAssistantFeature = this.props.user?.siteDetails?.siteConfig?.features?.includes?.("AsklyAssistant");
        let hasEmailFeature = this.props.user?.siteDetails?.siteConfig?.features?.includes?.("AsklyEmails");

        if (!isLoaded) {
            return <DefaultLayout match={this.props.match} />
        }

        return (
            <DefaultLayout match={this.props.match}>
                <div className="w-100 d-flex settings-page content-card tour-step-full-settings">
                    <SettingsNavigation
                        isAdminRights={isAdminRights}
                        isPersonalSettings={isPersonalSettings}
                        isSiteSettings={isSiteSettings}
                        isWebWidget={isWebWidget}
                        isMembersPage={isMembersPage}
                        isAsklyAssistantPage={isAsklyAssistantPage}
                        isBillingPage={isBillingPage}
                        isEmailPage={isEmailPage}
                        canSave={canSave}
                    />
                    <Switch >
                        <Route path="/settings/personal" component={Personal} />
                        <Route exact path="/settings/site" render={(props => <TeamSettings canSave={canSave} {...props} />)} />
                        <Route exact path="/settings/site/users" component={SiteMembers} />
                        {hasAssistantFeature && <Route exact path="/settings/site/assistant" component={AsklyAssistant} />}
                        {hasEmailFeature && isAdminRights && <Route exact path="/settings/site/email" component={Email} />}
                        {isAdminRights && <Route exact path="/settings/subscription" component={Subscription} />}
                        {isAdminRights && <Route exact path="/settings/site/web-widget" render={(props => <ChatSettings canSave={canSave} {...props} />)} />}
                        {isLoaded && <Redirect to={{ pathname: '/settings/personal', search: "?block" }} />}
                        {!isLoaded && <Route path="*" render={() => <></>} />}
                    </Switch>
                </div>
            </DefaultLayout >)
    }
}

const mapStateToProps = state => ({ user: state.user });
export default withTranslation('settings')(connect(mapStateToProps)(SettingsPage));



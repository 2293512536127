import { useForm } from '@mantine/form'
import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from "react-i18next"
import { executePromiseMinTime } from '../../logic/util'
import { useGetAssistantQuery, useImportAssistantQnAMutation, useUpdateAssistantSecondaryMutation } from '../../state/api'
import { CustomToggle } from '../base/minor/CustomDropdown'
import { addErrorToast, addGeneralTopToast, addInfoToast, clearTopToast } from '../base/ToastManager'
import './Modal.scss'


export default function AssistantHiddenModal({ data, show, size, onCancel, onClose, onConfirm }) {
    const { t } = useTranslation("common")
    const [state, setState] = useState({})
    const { data: assistantData, isLoading: assistantIsLoading } = useGetAssistantQuery()
    const [updateAssistant, { isLoading: isUpdating }] = useUpdateAssistantSecondaryMutation()
    const [importQnasFile] = useImportAssistantQnAMutation()

    let fileInput = useRef(null);
    const form = useForm({
        initialValues: {
            version: '1',
            generalContext: '',
        },
    });

    useEffect(() => {
        form.setValues({
            "version": assistantData?.version ?? "1",
            "generalContext": assistantData?.generalContext ?? "",
        })
    }, [assistantData])

    const confirmClick = () => {
        setState({ isLoading: true });
        executePromiseMinTime(Promise.resolve(updateAssistantWrap()), 500).then(() => {
            setState({ isLoading: false });
            onClose();
        });
    }

    const importQnas = (e) => {
        if (fileInput.current.files) {
            addGeneralTopToast(t("Uploading files..."))

            let uploadPromise = importQnasFile(fileInput.current.files?.[0]).unwrap().then(() => {
                addInfoToast(t("Files uploaded"))
            }).catch(() => {
                addErrorToast(t("Failed to upload document"))
            }).finally(() => {
                fileInput.current.value = ''
                fileInput.current.files = null
            })

            executePromiseMinTime(uploadPromise, 1500).finally(() => {
                clearTopToast()
            })
        }
    };
    const updateAssistantWrap = () => {
        const { version, generalContext } = form.values
        return updateAssistant({ version, generalContext }).unwrap().then(() => {
            addInfoToast(t("Assistant updated"))
        }).catch(() => {
            addErrorToast(t("Failed to update assistant"))
        })
    }

    const cancelClick = () => {
        if (onCancel) {
            onCancel()
            onClose();
        } else {
            onClose()
        }
    }

    data = data || {}
    const { version } = form.values

    return (
        <Modal size={size ? size : 'md'} className={cn("tw-modal")} centered show={show} onHide={onClose} animation={false}>
            <h5 className="modal-title my-2">{t("Assistant settings")}</h5>
            <div className='mx-3 mt-2'>
                <Form.Group>
                    <Dropdown>
                        <Form.Label>{t("Version")}<span className="general-error ml-2">{""}</span></Form.Label>
                        <Dropdown.Toggle as={CustomToggle}>
                            {t("Version")} : {version}
                        </Dropdown.Toggle>
                        <Dropdown.Menu >
                            <Dropdown.Item onSelect={() => { form.setFieldValue("version", "1") }} eventKey={"1"}>{"Version 1"}</Dropdown.Item>
                            <Dropdown.Item onSelect={() => { form.setFieldValue("version", "2") }} eventKey={"2"}>{"Version 2"}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("General info")}<span className="general-error ml-2">{""}</span></Form.Label>
                    <Form.Control rows="4" as="textarea" type="text" placeholder="Company operates in .." row={5} maxLength={655} {...form.getInputProps('generalContext')} />
                </Form.Group>
            </div>

            <div className="mt-4 mb-2 mx-3 d-flex">
                <Button variant="success" className="mr-auto" onClick={() => { fileInput?.current?.click() }}>{t("Import")}</Button>
                <input ref={fileInput} onChange={importQnas} type="file" accept=".csv" multiple={false} disabled={false} style={{ display: 'none' }} />
                <Button disabled={state.isLoading} className={cn({ "mr-3": !data.hideConfirm })} variant="outline-primary" onClick={cancelClick}>
                    {t("Cancel")}
                </Button>
                <Button disabled={state.isLoading} variant="primary" onClick={confirmClick}>
                    {!state.isLoading && <span className="confirm-text">{t("Save")}</span>}
                    {state.isLoading && <div className="loader">{t("Loading...")}</div>}
                </Button>
            </div>
        </Modal>
    );
}
